body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.page {
  position: relative;
  width: 100%;
  height: 100vh;
}

.container {
  padding: 20px;
  overflow-y: scroll;
  height: calc(100vh - 88px);
}

.withAppBar {
  height: calc(100vh - 90px) !important;

}

.withAppBarAndTabs {
  height: calc(100vh - 140px) !important;
}

.root {
  flex-grow: 1;
  width: 100%;
  padding-top: 48px;
}

.tabsRoot {
  border-bottom: 1px solid #e8e8e8;
  background: white;
}

.tabsIndicator {
  background-color: #000000;
}

.tabRoot: {
  text-transform: 'initial';
  min-width: 72px;
  font-family: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ];
}

.tabRoot:hover {
    color: #000000;
    opacity: 1;
}

.tabRoot:focus {
  color: #000000;
}

.tabSelected {
  color: #000000;
  background: #000000;
};
